import { UStoreProvider } from '@ustore/core'

export const initiateThemeState = async () => {
  const { Categories: categoriesTree } = await UStoreProvider.api.categories.getCategoryTree(3)
  const firstLevelCategories = categoriesTree.map((node) => node.Category)
  const categoryIDs = firstLevelCategories.map((node) => node.ID)
  const productsPromises = categoryIDs.map(async function(ID) { 
    const { Products } = await UStoreProvider.api.products.getProducts(ID); 
    return await Products; 
  })
  const productsArray = await Promise.all(productsPromises)
  const subCategoriesPromises = categoryIDs.map(async function(ID) { 
    const { Categories } = await UStoreProvider.api.categories.getSubCategories(ID); 
    return await Categories; 
  })
  const subCategoriesArray = await Promise.all(subCategoriesPromises)
  const subCategoriesFiltered = subCategoriesArray.filter(n => n.length)
  const subCategories = subCategoriesFiltered[0].map((node) => node.ID)
  const subCategoryProductsPromises = subCategories.map(async function(ID) { 
    const { Products } = await UStoreProvider.api.products.getProducts(ID); 
    return await Products; 
  })
  const subCategoryProductsArray = await Promise.all(subCategoryProductsPromises)

  UStoreProvider.state.customState.set('categories', firstLevelCategories)
  UStoreProvider.state.customState.set('categoriesTree', categoriesTree)
  UStoreProvider.state.customState.set('categoryIDs', categoryIDs)
  UStoreProvider.state.customState.set('allProducts', productsArray)
  UStoreProvider.state.customState.set('subCategoryProducts', subCategoryProductsArray)

  if (UStoreProvider.state.get().currentStore) {
    const { RequireOrderApproval: requireOrderApproval } = UStoreProvider.state.get().currentStore

    if (requireOrderApproval) {
      const userOrdersSummary = await UStoreProvider.api.orders.getUserOrdersSummary()
      UStoreProvider.state.customState.set('userOrdersSummary', userOrdersSummary)
    }
  }
}
