import React from 'react'
import './Footer.scss'
import Slot from '../widgets/Slot'
import { Icon } from '$core-components'

/**
 * This component represents the footer in the store
 */
const Footer = () => {

  const facebookUrl = "https://www.facebook.com/pelhughesNOLA/"
  const xUrl = "http://x.com/PelHughes"
  const youtubeUrl = "http://www.youtube.com/channel/UCawvAA4I2j6aZ_qqh-qxOJA"
  const linkedinUrl = "https://www.linkedin.com/company/pel-hughes?trk=company_logo"
  const instagramUrl = "https://www.instagram.com/pelhughes/"

  return (
    <div className="footer">
      <Slot name="footer" />
      <div className="footer-column">
        <p>
          <b>PelPrint.com</b><br />
          3801 Toulouse Street<br />
          New Orleans, LA 70119<br />
          <a href="mailto:support@pelprint.com">support@pelprint.com</a><br />
          (504) 486-8646
        </p>
        <div className="social-links-container">
          <div class="social-link">
            <a href={facebookUrl} target="_blank"><Icon name="facebook_logo.svg" width="25px" height="25px" /></a>
          </div>
          <div class="social-link">
            <a href={xUrl} target="_blank"><Icon name="x_logo.svg" width="25px" height="25px" /></a>
          </div>
          <div class="social-link">
            <a href={youtubeUrl} target="_blank"><Icon name="youtube_logo.svg" width="25px" height="25px" /></a>
          </div>
          <div class="social-link">
            <a href={linkedinUrl} target="_blank"><Icon name="linkedin_logo.svg" width="25px" height="25px" /></a>
          </div>
          <div class="social-link">
            <a href={instagramUrl} target="_blank"><Icon name="instagram_logo.svg" width="25px" height="25px" /></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
