/**
 * A menu strip showing all L1 categories in the system in desktop view
 *
 * @param {object} categoriesTree - a list of CategoryTreeNodeModel, each element denotes a tree node in the categories tree structure.
 */

import { Component } from 'react'
import { Link } from 'react-router-dom'
import { Popover, PopoverBody } from 'reactstrap'
import NavigationMenuWithProducts from './NavigationMenuWithProducts'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import { t } from '$themelocalization'
import { UStoreProvider } from '@ustore/core'
import './CategoriesNavbar.scss'

class CategoriesNavbar extends Component {
  constructor (props) {
    super(props)

    this.mouseOverElement = false
    this.categoryChanged = false

    this.state = {
      selectedCategory: null,
      isOverlayOpen: false,
      popoverTarget: 'id-0',
      keepNavigationOpen: false,
      categoryProductsTree: []
    }
  }

  handleMouseOut () {
    if (!this.state.keepNavigationOpen) {
      this.mouseOverElement = false

      // close popover with delay to handle a case where mouse is already over another element
      setTimeout(() => {
        if (!this.mouseOverElement) {
          this.setState({ selectedCategory: null, isOverlayOpen: false })
        } else {
          // if mouse is already over another element check if the category has changed - if so close the popover and open again (for refresh), else do nothing
          if (this.categoryChanged) {
            this.setState({ isOverlayOpen: false })
            if (this.state.selectedCategory.HasSubCategories || this.state.categoryProductsTree.length > 0) // handling the case when popover shouldn't open because there are no sub categories
            { this.setState({ isOverlayOpen: true }) }
          }
        }
      }, 5)
    }
  }

  handleMouseOver (category) {
    if (category && category.FriendlyID !== 0) {
      let { categoryIDs: categoryIDTree } = UStoreProvider.state.customState.get()
      let { allProducts: productsTree } = UStoreProvider.state.customState.get()
      const selectedCategoryIndex = categoryIDTree.indexOf(category.ID)
      const selectedCategoryProducts = productsTree[selectedCategoryIndex]
      this.setState( { categoryProductsTree: selectedCategoryProducts } )
    }

    this.mouseOverElement = true

    // if !category its the popover - dont do anything
    if (!category) { return }

    this.categoryChanged = !this.state.selectedCategory || (this.state.selectedCategory.FriendlyID !== category.FriendlyID)

    this.setState({ selectedCategory: category })

    // if category doesn't have sub categories or its the popover - return
    //if ((category && category.FriendlyID !== 0)) { return }

    const popoverTarget = `id-${category.FriendlyID}`
    this.setState({ popoverTarget, isOverlayOpen: true })
    //this.setState({ popoverTarget: `id-${category.FriendlyID}`, isOverlayOpen: true })

    
    const popover = document.querySelector('#popover-wrapper .show > div')
    if (!popover) {
      return
    }
    const nextTarget = document.getElementById(popoverTarget)
    const nextTargetRect = nextTarget.getBoundingClientRect()
    const m = popover.style.transform.match(/translate\(\s*\d+[a-z]{0,3}\s*,\s*(\d+[a-z]{0,3})\s*\)/)
    const currentTop = m ? m[1] : '149px'
    popover.style.transform = `translate(${nextTargetRect.x}px, ${currentTop})`
    
  }

  render () {
    const { categoriesTree, faqLink } = this.props
    if (!(categoriesTree && categoriesTree.length > 0)) {
      return null
    }

    const selected = this.state.selectedCategory
    const viewShowAll = selected && selected.FriendlyID === 0

    return (
      <div className="categories-navbar">
        <div className="category-title-wrapper" onMouseEnter={() => this.handleMouseOver({ FriendlyID: 0 })} onMouseLeave={() => this.handleMouseOut()}>
          <span className={`category-title ${selected && selected.FriendlyID === 0 ? 'highlight' : ''}`} id="id-0">
            {t('Header.All_Categories')}
          </span>
        </div>
        <div className="category-title-wrapper">
          <span className="category-title">
            <a className="faq link" href={faqLink} target="_blank">FAQ</a>
          </span>
        </div>
        <Link className="category-title-wrapper" to={urlGenerator.get({page: 'promo'})}>
              <span className="category-title">
                <span className="link">Promo Items</span>
              </span>
        </Link>
        {
          categoriesTree.map(({ Category }, i) => {
            const { FriendlyID, Name } = Category
            return <Link className="category-title-wrapper" onMouseEnter={() => this.handleMouseOver(Category)} onMouseLeave={() => this.handleMouseOut()}
              key={i} to={urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })} >
                <span className={`category-title ${selected && selected.FriendlyID === FriendlyID ? 'highlight' : ''}`} key={i} id={`id-${FriendlyID}`}>
                  <span className="link" key={i} dangerouslySetInnerHTML={{ __html: Name }}/>
                </span>
            </Link>
          })
        }
        
        <div id="popover-wrapper" className="popover-wrapper" />

        {
          selected &&
          <div onMouseEnter={() => this.handleMouseOver()} onMouseLeave={() => this.handleMouseOut()} keep-navigation-open={`${this.state.keepNavigationOpen}`}>
            <Popover className={viewShowAll ? 'view-show-all' : ''} container={"popover-wrapper"} fade={false} isOpen={this.state.isOverlayOpen} placement="bottom-start" target={this.state.popoverTarget}>
              <PopoverBody>
                <NavigationMenuWithProducts categoriesTree={categoriesTree} viewShowAll={viewShowAll} selectedCategory={selected} productsTree={this.state.categoryProductsTree}  />
              </PopoverBody>
            </Popover>
          </div>
        }
      </div>
    )
  }
}

export default CategoriesNavbar
