import Layout from '../components/Layout'
import './Category.scss'

const ContactUs = (props) => {

    const contactImg = 'ContactHeader.jpg';

    return (
    <Layout {...props} className="category">
        <div className="main-img" style={{ width: '100%', height: '400px', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundImage: `url('https://pelprint.pelhughes.com/ustorethemes/PelPrint/assets/images/${contactImg}')` }} />
        <div className="title" dangerouslySetInnerHTML={{ __html: 'Contact Us' }} />
        <div class="container">
            <p>Please add details below and we will get back to you with more information and pricing!</p>
            <form class="contact-form" action="https://formsubmit.co/support@pelprint.com" method="POST" enctype="multipart/form-data">
                <input type="hidden" name="_next" value="https://pelprint.com/en-US/home"></input>
                <input type="hidden" name="_subject" value="New submission from Contact Us page on PelPrint.com"></input>
                <div class="form-row">
                    <div class="input-data">
                    <input type="text" name="name" required />
                    <div class="underline"></div>
                    <label for="name">Name</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="input-data">
                    <input type="text" name="email" required />
                    <div class="underline"></div>
                    <label for="email">Email Address</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="input-data">
                    <input type="text" name="phone" required />
                    <div class="underline"></div>
                    <label for="phone">Phone Number</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="input-data textarea">
                        <textarea rows="8" cols="80" name="message" required></textarea>
                        <br />
                        <div class="underline"></div>
                        <label for="message">Message</label>
                        <br />
                    </div>
                </div>
                <div class="form-row submit-btn">
                    <div class="input-data">
                        <div class="inner"></div>
                        <input type="submit" value="submit" />
                    </div>
                </div>
            </form>
        </div>
    </Layout>
    )
}
  
export default ContactUs